export function reportError(error: unknown, label: string) {
  if (error instanceof Error) {
    console.error(
      JSON.stringify({
        type: `${label}.error`,
        error: error.message,
        stack: error.stack,
      }),
    );
  } else {
    console.error(
      JSON.stringify({
        type: `${label}.unknown_error`,
        error: error ?? '(empty)',
      }),
    );
  }
}
