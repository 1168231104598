import type { MetaFunction } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';

import { ShorebirdLogo } from './components/ShorebirdLogo';
import { reportError } from './utils/report_error';
import './tailwind.css';
import { getEnv } from './services/env.server';

export const meta: MetaFunction = () => {
  const title = 'Shorebird Console';
  const description = 'Code Push for Flutter.';
  const image = '/open-graph.png';
  const url = 'https://console.shorebird.dev';

  return [
    { title: title },
    { name: 'description', content: description },
    {
      tagName: 'link',
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon.png',
    },
    { property: 'twitter:card', content: 'summary' },
    { property: 'twitter:description', content: description },
    { property: 'twitter:image', content: image },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: title },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:url', content: url },
  ];
};

export async function loader() {
  return json({
    ENV: getEnv(),
  });
}

export function ErrorBoundary({ error }: { error: unknown }) {
  const routeError = useRouteError();
  const is404 = isRouteErrorResponse(routeError) && routeError?.status === 404;

  reportError(error, 'remix.client');

  if (is404) return <NotFound />;

  captureRemixErrorBoundaryError(error);

  return <InternalError />;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');
  return (
    <html lang="en" data-theme="business">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <script
          defer
          data-domain="console.shorebird.dev"
          src="https://plausible.io/js/script.tagged-events.js"
        ></script>
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data?.ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

function NotFound() {
  return (
    <html lang="en" data-theme="business">
      <head>
        <title>404 | Shorebird</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex h-screen flex-col items-center justify-center gap-4">
          <ShorebirdLogo className="h-48 w-48" />
          <h1 className="text-center text-3xl font-bold">
            Oh no!
            <br />
            This page does not exist!
          </h1>
          <p className="text-center text-lg leading-loose">
            The page you are looking for does not exist. Please check your URL
            for typos.
          </p>
        </div>
        <Scripts />
      </body>
    </html>
  );
}

function InternalError() {
  return (
    <html lang="en" data-theme="business">
      <head>
        <title>Oops! | Shorebird</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex h-screen flex-col items-center justify-center gap-4">
          <ShorebirdLogo className="h-48 w-48" />
          <h1 className="text-center text-3xl font-bold">
            Oops!
            <br />
            Something went wrong.
          </h1>
          <p className="text-center text-lg leading-loose">
            An error occurred while rendering this page.
            <br />
            Try refreshing the page and if the problem persists, please{' '}
            <a className="link" href="mailto:contact@shorebird.dev">
              contact us
            </a>
            .
          </p>
        </div>
        <Scripts />
      </body>
    </html>
  );
}
